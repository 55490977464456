import axios from "axios";

export const wilayahMixin = {
    data() {
        return {
            provinsiMixin: [],
            kabkotaMixin: [],
            kecamatanMixin: [],
            kelurahanMixin: []
        };
    },
    methods: {
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then(response => {
                    this.provinsiMixin = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getKabkota(id) {
            axios
                .get("/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" + id)
                .then(response => {
                    this.kabkotaMixin = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getKecamatan(id) {
            axios
                .get("/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" + id)
                .then(response => {
                    this.kecamatanMixin = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getKelurahan(id) {
            axios
                .get("/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" + id)
                .then(response => {
                    this.kelurahanMixin = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};

export default wilayahMixin