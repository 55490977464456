<template>
    <div>
        <div class="mb-4">
            <h4 class="font-weight-bold">PENANGGUNG JAWAB</h4>
            <em>
                <b>Penanggung Jawab</b> adalah orang yang ditunjuk Badan Usaha dengan bukti <b>Surat Ketetapan atau Surat Penunjukkan sebagai Penanggung Jawab penggunaan Aplikasi Retribusi Daerah Sumatera Utara</b>. Penanggung Jawab juga dapat diisi oleh Kepala Keuangan atau orang yang bertanggung jawab sebagai pengelola keuangan retribusi dalam suatu Badan Usaha.
            </em>
        </div>
        <div>
            <b-form
                @submit.prevent="
                    editMode
                        ? update(penanggungJawabId)
                        : store()
                "
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >NIK
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.nik"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nik'),
                            }"
                            placeholder="Masukkan NIK"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nik')"
                            class="form-text text-danger"
                            >{{ errors.nik[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.nama"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nama'),
                            }"
                            placeholder="Masukkan Nama"
                            style="text-transform: uppercase;"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nama')"
                            class="form-text text-danger"
                            >{{ errors.nama[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Jabatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.jabatan"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('jabatan'),
                            }"
                            placeholder="Masukkan Jabatan"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('jabatan')"
                            class="form-text text-danger"
                            >{{ errors.jabatan[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Surat Ketetapan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.no_sk"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('no_sk'),
                            }"
                            placeholder="Masukkan Nomor Surat Ketetapan"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('no_sk')"
                            class="form-text text-danger"
                            >{{ errors.no_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Tgl. Surat Ketetapan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <div class="input-group col-md-5 p-0">
                            <date-picker
                                id="dateTimeInput"
                                v-model="form.tgl_sk"
                                :config="options"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': errors.hasOwnProperty(
                                        'tgl_sk'
                                    ),
                                }"
                                placeholder="-Pilih Tanggal-"
                                :disabled="disableForm"
                            ></date-picker>
                            <div class="input-group-append">
                                <label
                                    for="dateTimeInput"
                                    class="input-group-text"
                                >
                                    <i class="fa fa-calendar"></i>
                                </label>
                            </div>
                        </div>
                        <em
                            v-if="errors.hasOwnProperty('tgl_sk')"
                            class="form-text text-danger"
                            >{{ errors.tgl_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Dokumen SK
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-2" v-if="editMode">
                        <b-button
                            block
                            variant="outline-danger"
                            @click="showPdf(form.dok_sk)"
                        >
                            <i class="fa fa-file-pdf"></i> Lihat Dokumen SK
                        </b-button>
                    </div>
                    <div class="col-md-6 mt-1">
                        <b-form-file
                            v-model="form.dok_sk"
                            plain
                            @change="handleFile"
                            :disabled="disableForm"
                        ></b-form-file>
                        <em
                            v-if="errors.hasOwnProperty('dok_sk')"
                            class="form-text text-danger"
                            >{{ errors.dok_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 mr-2"></div>
                    <div class="col-md-6">
                        <div class="row font-weight-bold text-danger">
                            Penting:
                        </div>
                        <ol class="p-0" style="line-height: 1.2em">
                            <li>
                                Dokumen Surat Ketetapan (SK) dapat berupa Akta
                                Pendirian (pada halaman Cover dan halaman yang
                                menunjukkan bukti sebagai Pimpinan Badan Usaha).
                            </li>
                            <li>
                                Atau Surat Ketetapan yang laingnya yang membuktikan
                                sebagai Pimpinan Badan Usaha.
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="mt-3 mb-2">
                    <h4>KONTAK & ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Handphone
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.nomp"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nomp'),
                            }"
                            placeholder="Masukkan Nomor Handphone"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nomp')"
                            class="form-text text-danger"
                            >{{ errors.nomp[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Telepon
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.notelepon"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty(
                                    'notelepon'
                                ),
                            }"
                            placeholder="Masukkan Nomor Telepon"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('notelepon')"
                            class="form-text text-danger"
                            >{{ errors.notelepon[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Email
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="email"
                            v-model="form.email"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('email'),
                            }"
                            placeholder="Masukkan Alamat Email"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('email')"
                            class="form-text text-danger"
                            >{{ errors.email[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Alamat
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.alamat"
                            class="form-control"
                            placeholder="Masukkan Alamat"
                            :disabled="disableForm"
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Provinsi
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="provinsiMixin"
                            :reduce="(nama) => nama.id"
                            v-model="wilayah.provinsi"
                            @input="onProvinsi(wilayah.provinsi)"
                            placeholder="Pilih Provinsi"
                            :disabled="disableForm"
                        >
                        </v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kabkotaMixin"
                            v-model="wilayah.kabkota"
                            :reduce="(nama) => nama.id"
                            @input="onKabkota(wilayah.kabkota)"
                            placeholder="Pilih Kabupaten/Kota"
                            class="bg-white"
                            :disabled="disableForm"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kecamatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kecamatanMixin"
                            :reduce="(nama) => nama.id"
                            v-model="wilayah.kecamatan"
                            @input="onKecamatan(wilayah.kecamatan)"
                            placeholder="Pilih Kecamatan"
                            :disabled="disableForm"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kelurahan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kelurahanMixin"
                            :reduce="(nama) => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                            :disabled="disableForm"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kodepos
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('kodepos'),
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="errors.hasOwnProperty('kodepos')"
                            class="form-text text-danger"
                            >{{ errors.kodepos[0] }}</em
                        >
                    </div>
                </div>
                <hr />
                <div>
                    <span>
                        <b-button variant="primary" type="submit" class="px-4">
                            <span>
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i
                                        class="fa fa-save"
                                        v-if="
                                            !editMode
                                        "
                                    ></i>
                                    <i class="fa fa-edit" v-else></i>
                                </span>
                                <span
                                    v-if="
                                        !editMode
                                    "
                                >
                                    Simpan
                                </span>
                                <span v-else>
                                    Edit
                                </span>
                            </span>
                        </b-button>
                        <span class="px-2" v-if="!editMode">
                            <b-button variant="secondary">
                                <i class="fa fa-redo-alt"></i> Reset
                            </b-button>
                        </span>
                    </span>
                    <span class="float-right">
                        <div>
                            <span class="mx-2">
                                <b-button variant="danger" @click="prevButton">
                                    <i class="fa fa-arrow-circle-left"></i>
                                    Sebelumnya
                                </b-button>
                            </span>
                            <span>
                                <b-button
                                    variant="success"
                                    @click="selesaiButton"
                                    :disabled="disableNext"
                                >
                                    <i class="fa fa-check-circle"></i>
                                    Selesai
                                </b-button>
                            </span>
                        </div>
                    </span>
                </div>
            </b-form>
        </div>
        <!-- Modal pdf -->
        <b-modal
            id="modal-1"
            size="xl"
            v-model="showModalData"
            hide-footer
            hide-header
            title="Surat Ketetapan"
            no-close-on-backdrop
        >
            <b-row>
                <b-col>
                    <h5>Detail SURAT KETETAPAN</h5>
                </b-col>
                <b-col>
                    <span class="float-right pb-2">
                        <!-- <b-button variant="outline-dark" @click="closeModal"><i class="fa fa-times"></i></b-button> -->
                        <i
                            class="fa fa-times fa-2x tutup"
                            @click="closeModal"
                            v-b-tooltip.hover
                            title="Tutup"
                            style="cursor: pointer;"
                        ></i>
                    </span>
                </b-col>
            </b-row>
            <div v-if="notFound">
                <b-alert variant="danger" show class="text-center">
                    Data Surat Ketetapan tidak dapat ditemukan.
                </b-alert>
            </div>
            <div v-else>
                <iframe
                    :src="pdfUrl"
                    frameborder="1"
                    height="1100"
                    width="100%"
                ></iframe>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from "axios";
import { mapState } from "vuex";
import hapusStorage from '@/helper/hapusLocalStore'
import wilayahMixin from '@/helper/api/wilayah'

export default {
    mixins: [hapusStorage, wilayahMixin],
    data() {
        return {
            form: new Form({
                nik: "",
                npwrd_id: "",
                nama: "",
                nomp: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: "",
                jabatan: "",
                no_sk: "",
                tgl_sk: "",
                dok_sk: null,
                status_sk: 2,
                aktif: "",
            }),
            wilayah: {
                provinsi: "",
                kabkota: "",
                kecamatan: "",
            },
            isLoading: false,
            editMode: false,
            errors: [],
            options: {
                format: "YYYY-MM-DD",
                useCurrent: false,
            },
            editMode: false,
            isLoading: false,
            user: JSON.parse(localStorage.getItem("user")),
            penanggungJawabId: JSON.parse(localStorage.getItem('wakil')).penanggung_jawab,
            statusAktifOptions: [
                {
                    id: 0,
                    nama: "Non Aktif",
                },
                {
                    id: 1,
                    nama: "Aktif",
                },
            ],
            pdfUrl: null,
            showModalData: false,
            notFound: false,
            disableForm: false,
            detailWakil: "",
            disableNext: true,
        };
    },
    computed: {
        ...mapState(["WilayahIndonesia"]),
    },
    mounted() {
        this.getProvinsi()
        if ( JSON.parse(localStorage.getItem('wakil')).penanggung_jawab) {
            const pimpinanId = JSON.parse(localStorage.getItem('wakil')).penanggung_jawab
            this.getWakil(pimpinanId)
            this.editMode = true
        }
    },
    methods: {
        handleFile(event) {
            const file = event.target.files[0];
            this.form.dok_sk = file;
        },
        // store data
        store() {
            this.isLoading = true;
            this.form.npwrd_id = this.user.npwrd_id;
            this.form.nama = this.form.nama.toUpperCase();
            const formData = new FormData();
            formData.append("dok_sk", this.form.dok_sk);
            formData.append("nik", this.form.nik);
            formData.append("npwrd_id", this.form.npwrd_id);
            formData.append("nama", this.form.nama);
            formData.append("nomp", this.form.nomp);
            formData.append("notelepon", this.form.notelepon);
            formData.append("email", this.form.email);
            formData.append("alamat", this.form.alamat);
            formData.append(
                "wilayah_kelurahan_id",
                this.form.wilayah_kelurahan_id
            );
            formData.append("kodepos", this.form.kodepos);
            formData.append("jabatan", this.form.jabatan);
            formData.append("no_sk", this.form.no_sk);
            formData.append("tgl_sk", this.form.tgl_sk);
            formData.append("status_sk", this.form.status_sk);
            axios
                .post("/api/wajib-retribusi/npwrd-wakil", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    let penanggung_jawab = JSON.parse(localStorage.getItem("wakil"));
                    penanggung_jawab.penanggung_jawab = response.data.data.id;
                    localStorage.setItem("wakil", JSON.stringify(penanggung_jawab));
                    this.isLoading = false;
                    this.errors = [];
                    this.disableNext = true;
                    this.editMode = true
                    this.$store.dispatch("Pendaftaran/isEventDisable2", false);
                    this.$swal({
                        icon: "success",
                        title: "Data Pimpinan Berhasil Ditambah.",
                        html:
                            "Anda dapat menambah data <b>Penanggung Jawab</b> dengan klik tombol 'Selanjutnya'",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                    })
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.disableNext = false;
                    this.editMode = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status == 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                    console.log(error);
                });
        },
        // update data
        update(id) {
            this.isLoading = true;
            this.form.npwrd_id = this.user.npwrd_id;
            this.form.nama = this.form.nama.toUpperCase();
            this.form.dok_sk = "";
            const formData = new FormData();
            formData.append("dok_sk", this.form.dok_sk);
            formData.append("nik", this.form.nik);
            formData.append("npwrd_id", this.form.npwrd_id);
            formData.append("nama", this.form.nama);
            formData.append("nomp", this.form.nomp);
            formData.append("notelepon", this.form.notelepon);
            formData.append("email", this.form.email);
            formData.append("alamat", this.form.alamat);
            formData.append("aktif", this.form.aktif);
            formData.append(
                "wilayah_kelurahan_id",
                this.form.wilayah_kelurahan_id
            );
            formData.append("kodepos", this.form.kodepos);
            formData.append("jabatan", this.form.jabatan);
            formData.append("no_sk", this.form.no_sk);
            formData.append("tgl_sk", this.form.tgl_sk);
            formData.append("status_sk", this.form.status_sk);
            formData.append("_method", "PUT");
            axios
                .post("/api/wajib-retribusi/npwrd-wakil/" + id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.editMode = true;
                    this.isLoading = false;
                    this.isReset = false;
                    this.errors = [];
                    this.$swal({
                        icon: "success",
                        title: "Data Penanggung Jawab Berhasil Dirubah.",
                        text: "Silahkan periksa kembali data anda.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000,
                    });
                    /*  this.form.reset()
                this.kodeNama= ''
                this.message = false */
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status == 401) {
                         // hapus storage mixins
                        this.clearAll()
                    }
                    console.log(error);
                });
        },
        //Wilayah
        // data detail wakil
        getWakil(id) {
            axios
                .get("/api/wajib-retribusi/npwrd-wakil/" + id)
                .then((response) => {
                    this.detailWakil = response.data.data;
                    this.form.fill(this.detailWakil);
                    this.wilayah.provinsi = this.detailWakil.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id;
                    this.getKabkota(this.wilayah.provinsi)
                    this.wilayah.kabkota = this.detailWakil.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id;
                    this.getKecamatan(this.wilayah.kabkota)
                    this.wilayah.kecamatan = this.detailWakil.wilayah_kelurahan.wilayah_kecamatan_id;
                    this.getKelurahan(this.wilayah.kecamatan)
                    this.form.wilayah_kelurahan_id = parseInt(this.form.wilayah_kelurahan_id)
                    this.editMode = true
                    this.disableNext = false
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                         // hapus storage mixins
                        this.clearAll()
                    }
                });
        },

        // reset
        reset() {
            this.form.reset();
            this.input.provinsi = null;
            this.input.kota = null;
            this.input.kecamatan = null;
            this.input.kabupaten = null;
        },
        // pdf
        showPdf(id) {
            this.$root.$emit("bv::show::modal", "modal-1");
            this.showModalData = true;
            this.getPdf(id);
        },
        // pdf
        getPdf(value) {
            axios
                .get(
                    "/api/wajib-retribusi/npwrd-wakil/view-pdf/doksk-" + value,
                    {
                        responseType: "arraybuffer",
                    }
                )
                .then((response) => {
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 404) {
                        this.closeModal();
                        this.$swal({
                            icon: "error",
                            title: "Dokumen SK Tidak Ditemukan.",
                            text: "Anda belum upload dokumen SK untuk NPWRD Wakil ini.",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            timer: 5000
                        });
                    } else if (error.response.status == 401) {
                         // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = "";
        },

        prevButton() {
            this.$store.commit("StepWajib/prevTab")
        },
        selesaiButton() {
            this.$router.push({ name: 'DetailWajibRetribusi'})
            this.$store.commit("StepWajib/resetTab")
        },

        // wilayah
        //provinsi
        onProvinsi (id) {
            if (id) {
                this.getKabkota(id)
                this.wilayah.kabkota = ''
                this.wilayah.kecamatan = ''
                this.form.wilayah_kelurahan_id = ''
            } else {
                this.kabkotaMixin = []
                this.kecamatanMixin = []
                this.kelurahanMixin = []
                this.wilayah.kabkota = ''
                this.wilayah.kecamatan = ''
                this.form.wilayah_kelurahan_id = ''
            }
        },
        // kabkota
        onKabkota (id) {
            if (id) {
                this.getKecamatan(id)
                this.wilayah.kecamatan = ''
                this.form.wilayah_kelurahan_id = ''
            } else {
                this.kecamatanMixin = []
                this.kelurahanMixin = []
                this.wilayah.kecamatan = ''
                this.form.wilayah_kelurahan_id = ''
            }
        },
        // kecamatan
        onKecamatan (id) {
            if (id) {
                this.getKelurahan(id)
                this.form.wilayah_kelurahan_id = ''
            } else {
                this.kelurahanMixin = []
                this.form.wilayah_kelurahan_id = ''
            }
        }
    },
};
</script>
