var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{attrs:{"nav-class":"nav-tabs-inverse bg-success","nav-wrapper-class":"nav nav-tabs nav-tabs-inverse bg-success","active-nav-item-class":"font-weight-bold"},model:{value:(_vm.StepWajib.tabIndex),callback:function ($$v) {_vm.$set(_vm.StepWajib, "tabIndex", $$v)},expression:"StepWajib.tabIndex"}},[_c('b-tab',[_c('template',{slot:"title"},[_c('span',{staticClass:"d-sm-none"},[_vm._v("Tab 1")]),_c('span',{class:{
                        'd-sm-block': true,
                        'd-none': true
                    }},[_vm._v("Step 1 - Profil Wajib Retribusi")])]),_c('Profil')],2),_c('b-tab',{attrs:{"disabled":_vm.StepWajib.perorangan}},[_c('template',{slot:"title"},[_c('span',{staticClass:"d-sm-none"},[_vm._v("Tab 2")]),_c('span',{class:{
                        'd-sm-block': true,
                        'd-none': true,
                        'line-through': _vm.StepWajib.perorangan
                    }},[_vm._v("Step 2 - Pimpinan")])]),_c('Pimpinan',{key:_vm.StepWajib.key})],2),_c('b-tab',{attrs:{"disabled":_vm.StepWajib.perorangan}},[_c('template',{slot:"title"},[_c('span',{staticClass:"d-sm-none"},[_vm._v("Tab 3")]),_c('span',{class:{
                        'd-sm-block': true,
                        'd-none': true,
                        'line-through': _vm.StepWajib.perorangan
                    }},[_vm._v("Step 3 - Penanggung Jawab")])]),_c('PenanggungJawab',{key:_vm.StepWajib.key})],2)],1),_c('div',{staticClass:"mb-4"}),_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }