<template>
    <div>
        <div class="mb-4">
            <h4 class="font-weight-bold">WAJIB RETRIBUSI DAERAH</h4>
            <em>
                Wajib Retribusi Daerah adalah <b>Perorangan</b> atau
                <b> Badan Usaha </b> yang memiliki kewajiban retribusi di daerah
                Provinsi Sumatera Utara.
            </em>
        </div>
        <div>
            <b-form
                @submit.prevent="editMode ? update(user.npwrd_id) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Jenis
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-3">
                        <v-select
                            :options="['Perorangan', 'Badan']"
                            v-model="form.jenis"
                            @input="inputJenis(form.jenis)"
                            placeholder="Pilih Jenis"
                            class="bg-white"
                            :disabled="jenisDisable"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('jenis')"
                            class="form-text text-danger"
                            >{{ errors.jenis[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1" v-show="disableBentuk">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Bentuk Badan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-3">
                        <v-select
                            label="nama"
                            :options="BentukBadan.bentuk_badan"
                            :reduce="nama => nama.id"
                            v-model="form.bentuk_badan_id"
                            placeholder="Pilih Bentuk Badan"
                            class="bg-white"
                            :disabled="disableForm"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('bentuk_badan_id')"
                            class="form-text text-danger"
                            >{{ errors.bentuk_badan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        ><span v-if="disableBentuk">
                            NIB
                        </span>
                        <span v-else>
                            NIK
                        </span>
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('no_induk')
                            }"
                            v-model="form.no_induk"
                            :placeholder="
                                disableBentuk ? 'Masukkan NIB' : 'Masukkan NIK'
                            "
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('no_induk')"
                            class="form-text text-danger"
                            >{{ errors.no_induk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama Wajib Retribusi
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('nama')
                            }"
                            v-model="form.nama"
                            placeholder="Masukkan Nama"
                            style="text-transform: uppercase;"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('nama')"
                            class="form-text text-danger"
                            >{{ errors.nama[0] }}</em
                        >
                        <div class="col-md-12">
                            <div class="row font-weight-bold text-danger">
                                Perhatian:
                            </div>
                            <ol class="p-0" style="line-height: 1.2em">
                                <li>
                                    Masukkan hanya nama badan usaha (contoh: PT.
                                    Indonesia Sejahtera Bersatu menjadi
                                    Indonesia Sejahtera Bersatu).
                                </li>
                                <li>
                                    Masukkan ejaan sesuai dengan NIB (jika Badan
                                    Usaha) atau NIK (jika Perorangan).
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="py-3">
                    <h4>KONTAK & ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor HP
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('nomp')
                            }"
                            v-model="form.nomp"
                            placeholder="Masukkan Nomor Handphone"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('nomp')"
                            class="form-text text-danger"
                            >{{ errors.nomp[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor Telepon
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.notelepon"
                            placeholder="Masukkan Nomor Telepon"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('notelepon')"
                            class="form-text text-danger"
                            >{{ errors.notelepon[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Email
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-5">
                        <input
                            type="email"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('email')
                            }"
                            v-model="form.email"
                            placeholder="Masukkan Alamat Email"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('email')"
                            class="form-text text-danger"
                            >{{ errors.email[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Alamat
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.alamat"
                            placeholder="Masukkan Alamat"
                            :disabled="disableForm"
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Provinsi
                    </label>
                    <div class="col-md-3">
                        <v-select
                            label="nama"
                            :options="provinsiMixin"
                            :reduce="nama => nama.id"
                            v-model="wilayah.provinsi"
                            @input="onProvinsi(wilayah.provinsi)"
                            placeholder="Pilih Provinsi"
                            :disabled="disableForm"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                    </label>
                    <div class="col-md-3">
                        <v-select
                            label="nama"
                            :options="kabkotaMixin"
                            v-model="wilayah.kabkota"
                            :reduce="nama => nama.id"
                            @input="onKabkota(wilayah.kabkota)"
                            placeholder="Pilih Kabupaten/Kota"
                            class="bg-white"
                            :disabled="disableForm"
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kecamatan
                    </label>
                    <div class="col-md-3">
                        <v-select
                            label="nama"
                            :options="kecamatanMixin"
                            :reduce="nama => nama.id"
                            v-model="wilayah.kecamatan"
                            @input="onKecamatan(wilayah.kecamatan)"
                            placeholder="Pilih Kecamatan"
                            :disabled="disableForm"
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kelurahan
                    </label>
                    <div class="col-md-3">
                        <v-select
                            label="nama"
                            :options="kelurahanMixin"
                            :reduce="nama => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kodepos
                    </label>
                    <div class="col-md-3">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                            :disabled="disableForm"
                        />
                    </div>
                </div>
                <hr />
                <div>
                    <span>
                        <b-button variant="primary" type="submit" class="px-4">
                            <span>
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i class="fa fa-save" v-if="!editMode"></i>
                                    <i class="fa fa-edit" v-else></i>
                                </span>
                                <span v-if="!editMode">
                                    Simpan
                                </span>
                                <span v-else>
                                    Edit
                                </span>
                            </span>
                        </b-button>
                        <span class="px-2">
                            <b-button variant="secondary" v-if="!editMode">
                                <i class="fa fa-redo-alt"></i> Reset
                            </b-button>
                        </span>
                    </span>
                    <span class="float-right">
                        <div>
                            <b-button
                                variant="success"
                                @click="nextButton"
                                :disabled="isDisabled"
                            >
                                Selanjutnya
                                <i class="fa fa-arrow-circle-right"></i>
                            </b-button>
                        </div>
                    </span>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import { mapState } from "vuex";
import hapusStorage from "@/helper/hapusLocalStore";
import wilayahMixin from "@/helper/api/wilayah";
import axios from "axios";

export default {
    mixins: [hapusStorage, wilayahMixin],
    props: ["detailProfil"],
    data() {
        return {
            form: new Form({
                bentuk_badan_id: "",
                jenis: "",
                nama: "",
                nomp: "",
                no_induk: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: null,
                kodepos: ""
            }),
            wilayah: {
                provinsi: "",
                kabkota: "",
                kecamatan: ""
            },
            isLoading: false,
            errors: [],
            disableBentuk: false,
            user: JSON.parse(localStorage.getItem("user")),
            disableForm: false,
            isDisabled: true,
            editMode: false,
            jenisDisable: false,
        };
    },
    mounted() {
        this.getProvinsi();
        if (this.$gates.getRoles()[0] === "Wajib Retribusi") {
            if (this.user.npwrd_id === null) {
                this.$swal({
                    icon: "warning",
                    title: "Anda Belum Mendaftarkan Data Wajib Retibusi.",
                    text: "Silahkan isi form yang tersedia untuk mendaftar.",
                    confirmButtonClass: "btn btn-info",
                    showConfirmButton: true,
                    confirmButtonClass: "btn btn-primary",
                    confirmButtonText: "OKE",
                    showCloseButton: true
                });
            } else {
                this.getNpwrd(this.user.npwrd_id);
                this.editMode = true;
            }
        }
    },
    computed: {
        ...mapState(["BentukBadan", "StepWajib", "WilayahIndonesia"])
    },
    methods: {
        // store data wajib retribusi
        store() {
            this.isLoading = true;
            this.form.nama = this.form.nama.toUpperCase();
            this.form
                .post("/api/wajib-retribusi/npwrd")
                .then(response => {
                    if (this.form.successful) {
                        const items = response.data.data;
                        this.isLoading = false;
                        this.editMode = true;
                        let wakil = {
                            pimpinan: items.pimpinan,
                            penanggung_jawab: items.penanggung_jawab
                        };
                        if (items.jenis > 41) {
                            this.isDisabled = true;
                            localStorage.setItem(
                                "wakil",
                                JSON.stringify(wakil)
                            );
                        } else if (items.jenis < 41) {
                            this.isDisabled = false;
                            localStorage.setItem(
                                "wakil",
                                JSON.stringify(wakil)
                            );
                            this.$store.commit("inputPimpinan", items.pimpinan)
                            this.$store.commit("inputPenanggungJawab", items.penanggung_jawab)
                        }
                        const npwrd_id = items.id;
                        let user = JSON.parse(localStorage.getItem("user"));
                        user.npwrd_id = npwrd_id;
                        localStorage.setItem("user", JSON.stringify(user));
                        this.$swal({
                            icon: "success",
                            title: "Berhasil Menambah Data Wajib Retribusi.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonClass: "btn btn-primary",
                            confirmButtonText: "OKE",
                            showCloseButton: true
                        });
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    this.editMode = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 500) {
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            text: error.response.data.message,
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonClass: "btn btn-primary",
                            confirmButtonText: "OKE",
                            showCloseButton: true
                        });
                    }
                });
        },
        // edit data npwrd
        update(id) {
            this.isLoading = true;
            this.disableForm = true;
            this.form
                .put("/api/wajib-retribusi/npwrd/" + id)
                .then(response => {
                    if (this.form.successful) {
                        this.isLoading = false;
                        this.disableForm = false;
                        this.$swal({
                            icon: "success",
                            title: "Berhasil Merubah Data Wajib Retribusi.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonClass: "btn btn-primary",
                            confirmButtonText: "OKE",
                            showCloseButton: true
                        });
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    this.disableForm = false;
                    console.log(error);
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    }
                });
        },
        // input jenis
        inputJenis(value) {
            if (value === "Perorangan") {
                this.disableBentuk = false;
                this.form.bentuk_badan_id = "";
                this.$store.commit("StepWajib/inputPerorangan", true);
            } else if (value === "Badan") {
                this.disableBentuk = true;
                this.$store.dispatch("BentukBadan/bentukBadan");
                this.$store.commit("StepWajib/inputPerorangan", false);
            } else {
                this.disableBentuk = false;
                this.$store.commit("StepWajib/inputPerorangan", true);
            }
        },
        // reset
        reset() {
            this.form.reset();
            this.form.clear();
            this.wilayah.provinsi = null;
            this.wilayah.kabkota = null;
            this.wilayah.kecamatan = null;
            this.wilayah.kabupaten = null;
        },
        nextButton() {
            this.$store.commit("StepWajib/nextTab");
            this.$store.commit("StepWajib/inputKey")
        },

        // wilayah
        // provinsi
        onProvinsi(id) {
            if (id) {
                this.getKabkota(id);
                this.wilayah.kabkota = "";
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            } else {
                this.kabkotaMixin = [];
                this.kecamatanMixin = [];
                this.kelurahanMixin = [];
                this.wilayah.kabkota = "";
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            }
        },
        // kabkota
        onKabkota(id) {
            if (id) {
                this.getKecamatan(id);
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            } else {
                this.kecamatanMixin = [];
                this.kelurahanMixin = [];
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            }
        },
        // kecamatan
        onKecamatan(id) {
            if (id) {
                this.getKelurahan(id);
                this.form.wilayah_kelurahan_id = "";
            } else {
                this.kelurahanMixin = [];
                this.form.wilayah_kelurahan_id = "";
            }
        },
        // detail npwrd
        getNpwrd(id) {
            axios
                .get("/api/wajib-retribusi/npwrd/" + id)
                .then(response => {
                    const items = response.data.data;
                    this.form.fill(items);
                    this.editMode = true;
                    if (this.form.jenis > 41) {
                        this.form.jenis = "Perorangan";
                        this.disableBentuk = false;
                        this.jenisDisable = true;
                        this.$store.commit("StepWajib/inputPerorangan", true);
                        this.isDisabled = true;
                    } else {
                        this.form.jenis = "Badan";
                        this.disableBentuk = true;
                        this.$store.dispatch("BentukBadan/bentukBadan");
                        this.jenisDisable = true;
                        this.$store.commit("StepWajib/inputPerorangan", false);
                        this.isDisabled = false;
                    }
                    if (items.wilayah_kelurahan) {
                        this.wilayah.provinsi =
                            items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id;
                        this.getKabkota(this.wilayah.provinsi);
                        this.wilayah.kabkota =
                            items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id;
                        this.getKecamatan(this.wilayah.kabkota);
                        this.wilayah.kecamatan =
                            items.wilayah_kelurahan.wilayah_kecamatan_id;
                        this.getKelurahan(this.wilayah.kecamatan);
                        this.form.wilayah_kelurahan_id = parseInt(
                            this.form.wilayah_kelurahan_id
                        );
                    }

                    let wakil = {
                        pimpinan: '',
                        penanggung_jawab: ''
                    }
                    if (items.penanggung_jawab) {
                        wakil.penanggung_jawab = items.penanggung_jawab.id;
                        localStorage.setItem("wakil", JSON.stringify(wakil));
                    } else {
                        localStorage.setItem("wakil", JSON.stringify(wakil));
                    }
                    if (items.pimpinan) {
                        wakil.pimpinan = items.pimpinan.id;
                        localStorage.setItem("wakil", JSON.stringify(wakil))
                    } else {
                        localStorage.setItem("wakil", JSON.stringify(wakil));
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
};
</script>
