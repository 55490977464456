<template>
    <div>
        <!-- begin nav-tabs -->
        <b-tabs
            nav-class="nav-tabs-inverse bg-success"
            nav-wrapper-class="nav nav-tabs nav-tabs-inverse bg-success"
            v-model="StepWajib.tabIndex"
            active-nav-item-class="font-weight-bold"
        >
            <!-- begin tab -->
            <b-tab>
                <template slot="title">
                    <span class="d-sm-none">Tab 1</span>
                    <span
                        :class="{
                            'd-sm-block': true,
                            'd-none': true
                        }"
                        >Step 1 - Profil Wajib Retribusi</span
                    >
                </template>
                <Profil />
            </b-tab>
            <!-- end tab -->
            <!-- begin tab -->
            <b-tab :disabled="StepWajib.perorangan">
                <template slot="title">
                    <span class="d-sm-none">Tab 2</span>
                    <span
                        :class="{
                            'd-sm-block': true,
                            'd-none': true,
                            'line-through': StepWajib.perorangan
                        }"
                        >Step 2 - Pimpinan</span
                    >
                </template>
                <Pimpinan :key="StepWajib.key"/>
            </b-tab>
            <!-- end tab -->
            <!-- begin tab -->
            <b-tab :disabled="StepWajib.perorangan">
                <template slot="title">
                    <span class="d-sm-none">Tab 3</span>
                    <span
                        :class="{
                            'd-sm-block': true,
                            'd-none': true,
                            'line-through': StepWajib.perorangan
                        }"
                        >Step 3 - Penanggung Jawab</span
                    >
                </template>
                <PenanggungJawab :key="StepWajib.key" />
            </b-tab>
            <!-- end tab -->
        </b-tabs>
        <!-- end nav-tabs -->
        <div class="mb-4"></div>
        <div></div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import { mapState } from "vuex";
import hapusStorage from "@/helper/hapusLocalStore";
import Profil from "@/components/npwr/wajib_retribusi/Profil";
import Pimpinan from "@/components/npwr/wajib_retribusi/Pimpinan";
import PenanggungJawab from "@/components/npwr/wajib_retribusi/PenanggungJawab";

export default {
    mixins: [hapusStorage],
    props: ["detailProfil"],
    components: {
        Profil,
        Pimpinan,
        PenanggungJawab
    },
    data() {
        return {
            key: 0,
            form: new Form({
                bentuk_badan_id: "",
                jenis: "",
                nama: "",
                nomp: "",
                no_induk: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: null,
                kodepos: ""
            }),
            input: {
                provinsi: "",
                kabkota: "",
                kecamatan: ""
            },
            bentukBadanOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],
            isLoading: false,
            errors: [],
            disableBentuk: false,
            user: JSON.parse(localStorage.getItem("user")),
            detail: [],
            disableForm: false,
            disableEdit: false,
            role: JSON.parse(localStorage.getItem('roles')),
            disableNext: false,
            isActive: false,
            isActive2: false,
            isActive3: false,
            isDisable: false,
            key: 0
        };
    },
    mounted() {
        this.$store.commit("StepWajib/inputPerorangan", true);
    },
    computed: {
        ...mapState(["cekNpwrd", "StepWajib"])
    },
    methods: {
    }
};
</script>

<style scoped>
.line-through {
    text-decoration: line-through;
}
</style>
